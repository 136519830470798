import { createSlice } from '@reduxjs/toolkit';
import {
  checkVerificationCodeBuilder,
  fetchUserInfoBuilder,
  loginBuilder,
  requestVerificationCodeBuilder,
  resetPasswordBuilder,
  verifyEmailBuilder,
} from './builder';
import { LOGOUT } from '../../../utils/constants';

const INIT_STATE = {
  isLoggedIn: null,
  accessToken: null,
  pending: {
    login: false,
    requestVerificationCode: false,
    checkVerificationCode: false,
    resetPassword: false,
    verifyEmail: false,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: INIT_STATE,
  reducers: {
    setAccessToken: (state, { payload }) => {
      const { accessToken } = payload;
      state.accessToken = accessToken;
    },
  },
  extraReducers: builder => {
    loginBuilder(builder);
    fetchUserInfoBuilder(builder);
    requestVerificationCodeBuilder(builder);
    checkVerificationCodeBuilder(builder);
    resetPasswordBuilder(builder);
    verifyEmailBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE, isLoggedIn: false }));
  },
});

export const { setAccessToken } = authSlice.actions;

export const selectIsLoggedIn = state => state.auth.isLoggedIn;
export const selectAccessToken = state => state.auth.token;
export const selectAuthActionsPending = state => state.auth.pending;

export default authSlice.reducer;
