import { createAsyncThunk } from '@reduxjs/toolkit';
import InfluencerRequestService from '../../../api/services/InfluencerRequestService';
import { base64ToFile } from '../../../utils/util';

export const createInfluencerRequestPassword = createAsyncThunk(
  'influencerRequest/createInfluencerRequestPassword',
  async requestData => {
    return await InfluencerRequestService.createInfluencerRequestPassword(requestData);
  },
);

export const redeemInvitationCode = createAsyncThunk('influencerRequest/redeemInvitationCode', async requestData => {
  return await InfluencerRequestService.redeemInvitationCode(requestData);
});

export const updateBasicInfo = createAsyncThunk('influencerRequest/updateBasicInfo', async requestData => {
  const { name, dateOfBirth, profilePhoto, questionnaireData } = requestData;

  const formData = new FormData();

  const profilePhotoFile = base64ToFile(profilePhoto, 'profile-photo');
  formData.append('file', profilePhotoFile, profilePhotoFile.name);
  formData.append('name', name);
  formData.append('dateOfBirth', dateOfBirth);
  formData.append('questionnaireData', JSON.stringify(questionnaireData));
  return await InfluencerRequestService.updateBasicInfo(formData);
});

export const uploadPhoto = createAsyncThunk('influencerRequest/uploadPhoto', async requestData => {
  const { onUploadProgress, photo } = requestData;
  const formData = new FormData();
  formData.append('file', photo.file, photo.file.name);
  return await InfluencerRequestService.uploadPhoto(formData, onUploadProgress);
});

export const uploadVideo = createAsyncThunk('influencerRequest/uploadVideo', async requestData => {
  const { onUploadProgress, video } = requestData;
  const formData = new FormData();
  formData.append('file', video.file, video.file.name);
  return await InfluencerRequestService.uploadVideo(formData, onUploadProgress);
});

export const uploadAudio = createAsyncThunk('influencerRequest/uploadAudio', async requestData => {
  const { onUploadProgress, audio } = requestData;
  const formData = new FormData();
  formData.append('file', audio.file, audio.file.name);
  return await InfluencerRequestService.uploadAudio(formData, onUploadProgress);
});

export const getOrCreateIdVerificationToken = createAsyncThunk(
  'influencerRequest/getOrCreateIdVerificationToken',
  async () => {
    return await InfluencerRequestService.getOrCreateIdVerificationToken();
  },
);

export const getQuestionnaire = createAsyncThunk('influencerRequest/getQuestionnaire', async () => {
  return await InfluencerRequestService.getQuestionnaire();
});

export const acceptAgreement = createAsyncThunk('influencerRequest/acceptAgreement', async () => {
  return await InfluencerRequestService.acceptAgreement();
});
