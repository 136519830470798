import React from 'react';
import { UPLOAD_FILE_CONSTRAINTS, UPLOAD_MEDIA_FILE_ERROR_TYPE } from '../../utils/constants';
import { ButtonContainer, Text, UploadFileInput, UploadText } from './UploadMediaButton.styles';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload-cloud.svg';
import heic2any from 'heic2any';

const UploadMediaButton = ({ type, onChange, numOfUploadedMedia, disabled, setError }) => {
  const acceptedExtensionsText = UPLOAD_FILE_CONSTRAINTS[type].ALLOWED_EXTENSIONS_TEXT;

  const getAudioDuration = file => {
    return new Promise((resolve, reject) => {
      const audio = new Audio(URL.createObjectURL(file));
      audio.onloadedmetadata = () => {
        resolve(audio.duration);
      };
      audio.onerror = error => {
        reject(error);
      };
    });
  };

  const validateFile = async file => {
    const allowedExtensions = UPLOAD_FILE_CONSTRAINTS[type].ALLOWED_EXTENSIONS;
    const maxFileSizeInBytes = UPLOAD_FILE_CONSTRAINTS[type].MAX_SIZE_IN_BYTES;

    if (file.size < maxFileSizeInBytes) {
      const fileType = file.type.split('/')[1];
      if (allowedExtensions.includes(fileType)) {
        if (type === UPLOAD_FILE_CONSTRAINTS.TYPE.AUDIO) {
          try {
            const audioDuration = await getAudioDuration(file);
            if (audioDuration < 30) {
              setError(prev => [...prev, { type: UPLOAD_MEDIA_FILE_ERROR_TYPE.AUDIO_DURATION, fileName: file.name }]);
            }
          } catch (error) {
            setError(prev => [...prev, { type: UPLOAD_MEDIA_FILE_ERROR_TYPE.FILE_TYPE, fileName: file.name }]);
          }
        }
        if (fileType === 'heic') {
          const fileName = file.name.split('.')[0];
          const jpeg = await heic2any({ blob: file });
          const newFile = new File([jpeg], `${fileName}.png`);
          return newFile;
        }
      } else {
        setError(prev => [...prev, { type: UPLOAD_MEDIA_FILE_ERROR_TYPE.FILE_TYPE, fileName: file.name }]);
      }
    } else {
      setError(prev => [...prev, { type: UPLOAD_MEDIA_FILE_ERROR_TYPE.FILE_SIZE, fileName: file.name }]);
    }
    return file;
  };

  return (
    <ButtonContainer $noMediaUploaded={!numOfUploadedMedia}>
      <UploadFileInput
        type="file"
        title=""
        value=""
        disabled={disabled}
        multiple
        onChange={async e => {
          const { files: newFiles } = e.target;
          if (newFiles.length) {
            const media = [];
            for (const newFile of [...newFiles]) {
              const file = await validateFile(newFile);

              if (file) {
                media.push({ file, url: URL.createObjectURL(file) });
              }
            }
            if (media.length) {
              onChange(media);
            }
          }
        }}
        accept={acceptedExtensionsText}
      />
      <UploadIcon color={numOfUploadedMedia ? '#fff' : '#0F0624'} />
      <UploadText $noMediaUploaded={!numOfUploadedMedia}>
        {!numOfUploadedMedia ? 'Click to upload' : 'Upload'}
      </UploadText>
      {!numOfUploadedMedia && <Text>or drag and drop</Text>}
      {!numOfUploadedMedia && <Text>Supported Files: {UPLOAD_FILE_CONSTRAINTS[type].ALLOWED_EXTENSIONS_TEXT}</Text>}
    </ButtonContainer>
  );
};

export default UploadMediaButton;
