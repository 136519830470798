import styled from 'styled-components';
import { Text16Regular } from '../Text/Text.styles';
import { theme, Transition } from '../../../styles/constants';

export const SingleValueText = styled.span`
  color: ${theme.colors.textColor};
  font-size: 1.5rem;
`;

export const SingleValueTextLight = styled(SingleValueText)`
  opacity: 0.6;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled(Text16Regular)`
  margin-bottom: 0.6rem;
`;

export const selectStyles = {
  control: baseStyles => ({
    ...baseStyles,
    background: `#fff`,
    minHeight: '4.8rem',
    borderRadius: '100px',
    fontSize: '1.5rem',
    boxShadow: 'none',
    outline: 'none',
    borderColor: '#dedbf9',
    fontWeight: '400',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#dedbf9',
    },
  }),
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: '#54329e',
      fontSize: '1.5rem',
      fontWeight: '300',
    };
  },
  input: defaultStyles => {
    return {
      ...defaultStyles,
      color: theme.colors.textColor,
    };
  },

  menu: baseStyles => ({
    ...baseStyles,
    background: `#fff`,
    borderRadius: '24px',
    border: '1px solid',
    boxShadow: 'none',
    overflow: 'auto',
    borderColor: '#dedbf9',
  }),
  singleValue: baseStyles => ({
    ...baseStyles,
    color: theme.colors.textColor,
  }),
  option: baseStyles => ({
    ...baseStyles,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    gap: '1rem',
    color: theme.colors.textColor,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#adaae160',
      transition: Transition.hover.on,
    },
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: baseStyles => ({
    ...baseStyles,
    color: theme.colors.textColor,
  }),
};
