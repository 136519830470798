import styled from 'styled-components';
import { ReactComponent as ChevronIcon } from '../../../assets/icons/chevron_left.svg';

export const PaginationContainer = styled.ul`
  display: flex;
`;

export const ArrowContainer = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 2.4rem;
  height: 2.4rem;
  pointer-events: ${props => props.disabled && 'none'};
`;

export const ArrowIcon = styled(ChevronIcon)`
  color: ${props => (props.$disabled ? '#A9A9A970' : 'black')};
`;

export const RightArrowIcon = styled(ArrowIcon)`
  transform: rotate(180deg);
`;

export const PaginationItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: ${props => (props.selected ? 'black' : '#A9A9A970')};

  &:hover {
    cursor: ${props => (props.dots ? 'default' : 'pointer')};
  }
`;
