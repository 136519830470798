import styled from 'styled-components';
import { Text14Regular } from '../Text/Text.styles';

export const Container = styled.div`
  display: flex;
  gap: 2.4rem;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
`;

export const Label = styled(Text14Regular)``;
