import React from 'react';
import { Container, Content, Label } from './RadioButton.styles';
import { ReactComponent as CheckCircleEmptyIcon } from '../../../assets/icons/check-circle-empty.svg';
import { ReactComponent as CheckCircleIcon } from '../../../assets/icons/check-circle-broken.svg';

const RadioButton = ({ options, value, onChange }) => {
  return (
    <Container>
      {options.map(option => {
        return (
          <Content key={option.value} onClick={() => onChange(option.value)}>
            {value === option.value ? (
              <CheckCircleIcon width={24} height={24} color="#000" />
            ) : (
              <CheckCircleEmptyIcon color="#000" />
            )}
            <Label>{option.label}</Label>
          </Content>
        );
      })}
    </Container>
  );
};

export default RadioButton;
