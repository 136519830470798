import React, { useRef } from 'react';
import { ForgotPasswordButton, Form, StyledButton } from './LoginPage.styles';
import { AuthContainer, Input } from '../../../components';
import { notifyError } from '../../../utils/notify';
import { selectAuthActionsPending } from '../../../store/slices/auth/slice';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { login } from '../../../store/slices/auth/asyncThunks';
import { FORGOT_PASSWORD } from '../../../components/App/routes';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../../config';

export const loginFormSchema = yup
  .object({
    email: yup.string().trim().required('Email is required').email('Invalid email'),
    password: yup.string().trim().required('Password is required'),
  })
  .required();

const LoginPage = () => {
  const { login: loginPending } = useSelector(selectAuthActionsPending);
  const dispatch = useDispatch();
  const recaptchaRef = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    delayError: 300,
    resolver: yupResolver(loginFormSchema),
  });

  const onSubmit = async data => {
    const { email, password } = data;
    const recaptchaValue = await recaptchaRef.current.executeAsync();

    dispatch(
      login({
        email,
        password,
        token: recaptchaValue,
      }),
    )
      .unwrap()
      .then(() => {})
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <AuthContainer title="Log In" subtitle="Welcome back!">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input placeholder="Enter your email" {...register('email')} error={errors.email?.message} />
        <Input
          placeholder="Enter password"
          {...register('password')}
          error={errors.password?.message}
          type="password"
        />
        <ForgotPasswordButton to={`/${FORGOT_PASSWORD}`}>Forgot password?</ForgotPasswordButton>
        <StyledButton title="Continue" type="submit" isLoading={loginPending} />
      </Form>
      {/* <DontHaveAccountContainer>
        <Text15Light>Don’t have an account?</Text15Light>
        <RegisterButton to={`/${SIGN_UP}`}>Register</RegisterButton>
      </DontHaveAccountContainer> */}
      <ReCAPTCHA ref={recaptchaRef} sitekey={config.googleRecaptcha.key} size="invisible" />
    </AuthContainer>
  );
};

export default LoginPage;
