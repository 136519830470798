import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 1.6rem 2.4rem 1.6rem 1.2rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.02);
  background-color: #fff;
  height: 6.8rem;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
