import React from 'react';
import { Container, FormContainer, FormHeader, LeftSide, RightSide, Subtitle, Title } from './AuthContainer.styles';
import BackgroundImage from '../../../assets/images/background.png';
import LogoComponent from '../../LogoComponent/LogoComponent';

const AuthContainer = ({ children, title, subtitle }) => {
  return (
    <Container>
      <LeftSide>
        <LogoComponent />
        <FormContainer>
          <FormHeader>
            <Title>{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </FormHeader>
          {children}
        </FormContainer>
      </LeftSide>
      <RightSide>
        <img src={BackgroundImage} alt="auth-image" />
      </RightSide>
    </Container>
  );
};

export default AuthContainer;
