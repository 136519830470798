import React from 'react';
import { Container, Title } from './PageNotFound.styles';
import { LogoComponent } from '../../../components';

const PageNotFound = () => {
  return (
    <Container>
      <LogoComponent fullLogo id="pageNotFound" />
      <Title>Page not found</Title>
    </Container>
  );
};

export default PageNotFound;
