import React from 'react';
import BaseModal from '../utils/BaseModal/BaseModal';
import { ButtonWrapper, Form, CancelText } from './EditUserInfoModal.styles';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../../utils/inputs/Input/Input';
import { Button } from '../../utils/Button/Button';
import { changePassword } from '../../../store/slices/user/asyncThunks';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserActionsPending } from '../../../store/slices/user/slice';
import { notifyError, notifyInfo } from '../../../utils/notify';

const passwordFormSchema = yup
  .object({
    currentPassword: yup
      .string()
      .trim()
      .required('Current password is required')
      .min(6, 'Must be at least 6 characters'),
    password: yup.string().trim().required('New password is required').min(6, 'Must be at least 6 characters'),
  })
  .required();

const EditUserInfoModal = ({ modalRef }) => {
  const dispatch = useDispatch();
  const { changePassword: changePasswordPending } = useSelector(selectUserActionsPending);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      currentPassword: '',
      password: '',
    },
    delayError: 300,
    resolver: yupResolver(passwordFormSchema),
  });

  const onSubmit = data => {
    const { password, currentPassword } = data;

    dispatch(changePassword({ password, currentPassword }))
      .unwrap()
      .then(() => {
        modalRef.current.hide();
        notifyInfo('Password changed successfully');
        reset();
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <BaseModal
      title="Edit Password"
      ref={modalRef}
      onClose={() => {
        modalRef.current.hide();
      }}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Current Password"
          placeholder="Enter current password"
          {...register('currentPassword')}
          error={errors.currentPassword?.message}
          type="password"
        />
        <Input
          label="New Password"
          placeholder="Enter new password"
          {...register('password')}
          error={errors.password?.message}
          type="password"
        />
        <ButtonWrapper>
          <Button title="Save" isLoading={changePasswordPending} />
          <CancelText
            onClick={() => {
              modalRef.current.hide();
            }}>
            Cancel
          </CancelText>
        </ButtonWrapper>
      </Form>
    </BaseModal>
  );
};

export default EditUserInfoModal;
