import styled from 'styled-components';
import { Button } from '../../utils/Button/Button';

export const Form = styled.form``;

export const StyledButton = styled(Button)`
  width: 23rem;
  margin-inline: auto;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 1.2rem;
  margin-bottom: 2.4rem;
`;
