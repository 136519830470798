import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { Container } from './AuthLayout.styles';
import { HOME, UPDATE_PASSWORD } from '../../components/App/routes';
import { selectUser } from '../../store/slices/user/slice';
import { USER_ROLE } from '../../utils/constants';

function AuthLayout() {
  const user = useSelector(selectUser);

  if (user) {
    if (user.role === USER_ROLE.INFLUENCER_REQUEST) {
      return <Navigate to={`/${UPDATE_PASSWORD}`} />;
    } else {
      return <Navigate to={HOME} />;
    }
  }

  return (
    <Container>
      <Outlet />
    </Container>
  );
}

export default AuthLayout;
