import React from 'react';
import ReactModal from 'react-modal';
import {
  Content,
  ErrorText,
  Header,
  ModalStyle,
  OverlayStyle,
  PaymentSuccessText,
  ProcessingContent,
  TextProcessing,
} from './ProcessingPaymentModal.styles';
import { ReactComponent as CloseIcon } from '../../../assets/icons/x-circle.svg';
import { ReactComponent as CardIcon } from '../../../assets/icons/credit-card-refresh.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check-circle-broken.svg';
import DotsLoader from '../../../assets/gifs/dots_loader.gif';
import { Text20Bold } from '../../utils/Text/Text.styles';
import { Button } from '../../utils/Button/Button';
import Spinner from '../../Spinner/Spinner';

const ProcessingPaymentModal = ({ show, setShow, error, paymentSuccess }) => {
  const renderContent = () => {
    if (paymentSuccess) {
      return (
        <>
          <Header>
            <CheckIcon color="#FF0000" />
          </Header>
          <PaymentSuccessText>Payment Successful</PaymentSuccessText>
          <Button
            onClick={() => {
              setShow(false);
            }}
            title="Continue"
          />
        </>
      );
    } else if (error) {
      return (
        <>
          <Header>
            <CloseIcon color="#FF0000" />
            <Text20Bold>Payment Failed</Text20Bold>
          </Header>
          <ErrorText>{error}</ErrorText>
          <Button onClick={() => setShow(false)} title="Try Different Payment Method" />
        </>
      );
    } else {
      return (
        <ProcessingContent>
          <Header>
            <CardIcon />
            <Text20Bold>Payment Processing</Text20Bold>
          </Header>
          <Spinner src={DotsLoader} width={100} height={100} />
          <TextProcessing>Please do not leave this screen until your payment is confirmed.</TextProcessing>
        </ProcessingContent>
      );
    }
  };
  return (
    <ReactModal
      isOpen={show}
      closeTimeoutMS={300}
      onRequestClose={() => setShow(false)}
      className="_"
      overlayClassName="_"
      contentElement={props => (
        <ModalStyle {...props}>
          <Content>{renderContent()}</Content>
        </ModalStyle>
      )}
      overlayElement={(props, contentElement) => <OverlayStyle {...props}>{contentElement}</OverlayStyle>}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    />
  );
};

export default ProcessingPaymentModal;
