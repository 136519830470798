import styled from 'styled-components';
import { Text16Regular, Text32ExtraBold } from '../../../components/utils/Text/Text.styles';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.4rem;
  width: 42rem;
  padding-top: 4rem;

  @media only screen and (max-width: 500px) {
    padding: 2rem 1.6rem 5rem 1.6rem;
  }
`;

export const Title = styled(Text32ExtraBold)`
  text-align: center;
`;

export const Subtitle = styled(Text16Regular)`
  line-height: 2.2rem;
  text-align: center;
  padding-inline: 2rem;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;
