import React from 'react';
import { Container } from './DateRangePickerComponent.styles';
import DateRangePicker from 'rsuite/DateRangePicker';
import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
} from 'date-fns';
import { useMediaQuery } from 'react-responsive';

const DateRangePickerComponent = ({ value, setValue }) => {
  const smallScreen = useMediaQuery({ minWidth: 0, maxWidth: 800 });
  const mobile = useMediaQuery({ minWidth: 0, maxWidth: 500 });

  const predefinedRanges = [
    {
      label: 'Today',
      value: [startOfDay(new Date()), endOfDay(new Date())],
      placement: mobile ? 'bottom' : 'left',
    },
    {
      label: 'Yesterday',
      value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))],
      placement: mobile ? 'bottom' : 'left',
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: mobile ? 'bottom' : 'left',
    },
    {
      label: 'Last week',
      value: [addDays(startOfWeek(new Date()), -7), addDays(endOfWeek(new Date()), -7)],
      placement: mobile ? 'bottom' : 'left',
    },
    {
      label: 'Last 7 days',
      value: [subDays(new Date(), 6), endOfDay(new Date())],
      placement: mobile ? 'bottom' : 'left',
    },
    {
      label: 'Last 30 days',
      value: [subDays(new Date(), 29), endOfDay(new Date())],
      placement: mobile ? 'bottom' : 'left',
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), endOfDay(new Date())],
      placement: mobile ? 'bottom' : 'left',
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: mobile ? 'bottom' : 'left',
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), endOfDay(new Date())],
      placement: mobile ? 'bottom' : 'left',
    },
    {
      label: 'All time',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), endOfDay(new Date())],
      placement: mobile ? 'bottom' : 'left',
    },
  ];

  return (
    <Container>
      <DateRangePicker
        cleanable={false}
        ranges={predefinedRanges}
        style={{ width: 188 }}
        placement="bottomEnd"
        placeholder="Select Date Range"
        editable={false}
        caretAs={null}
        value={value}
        showHeader={false}
        showOneCalendar={smallScreen}
        onChange={setValue}
        format="yyyy-MM-dd"
      />
    </Container>
  );
};

export default DateRangePickerComponent;
