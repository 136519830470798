import React, { useRef } from 'react';
import { notifyError } from '../../../../utils/notify';
import {
  UploadFileInputContainer,
  UploadFileInput,
  Image,
  Container,
  LogoWrapper,
  UploadButton,
} from './UploadProfilePhotoInput.styles';
import ErrorContainer from '../../ErrorContainer/ErrorContainer';
import { UPLOAD_FILE_CONSTRAINTS } from '../../../../utils/constants';
import { ReactComponent as LogoIcon } from '../../../../assets/icons/logo.svg';

const UploadProfilePhotoInput = ({ value, onChange, className, error, hideErrorMessage }) => {
  const inputRef = useRef();

  const validateFile = file => {
    const allowedExtensions = UPLOAD_FILE_CONSTRAINTS.PHOTO.ALLOWED_EXTENSIONS;
    const maxFileSizeInBytes = UPLOAD_FILE_CONSTRAINTS.PHOTO.MAX_SIZE_IN_BYTES;
    if (file.size < maxFileSizeInBytes) {
      const fileType = file.type.split('/')[1];
      if (allowedExtensions.includes(fileType)) {
        return file;
      } else {
        notifyError('Unsupported type');
      }
    } else {
      notifyError('File size error');
    }
  };

  const acceptedExtensionsText = UPLOAD_FILE_CONSTRAINTS.PHOTO.ALLOWED_EXTENSIONS_TEXT;

  return (
    <Container className={className}>
      <UploadFileInputContainer>
        <LogoWrapper>
          {value ? <Image src={typeof value === 'string' ? value : value.url} alt="influencer-image" /> : <LogoIcon />}
        </LogoWrapper>
        <UploadFileInput
          ref={inputRef}
          type="file"
          title=""
          value=""
          onChange={e => {
            const { files: newFiles } = e.target;
            if (newFiles.length) {
              const file = validateFile(newFiles[0]);
              if (file) {
                onChange({ file, url: URL.createObjectURL(file) });
              }
            }
          }}
          accept={acceptedExtensionsText}
        />
        <UploadButton onClick={() => inputRef.current.click()}>Upload Profile Photo</UploadButton>
      </UploadFileInputContainer>
      {!hideErrorMessage && <ErrorContainer errorText={error} />}
    </Container>
  );
};

export default UploadProfilePhotoInput;
