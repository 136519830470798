import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/d/users';

class UserService {
  fetchUserInfo = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/me`, { withCredentials: true });
      throwErrorIfWrongStatus(response, 200);
      const { user, accessToken } = response.data.data;
      return { user, accessToken };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching user's info.");
    }
  };

  changePassword = async ({ currentPassword, password }) => {
    try {
      const body = {
        currentPassword,
        password,
      };
      const response = await axiosApiClient.put(`${BASE_URL}/change-password`, body);
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, "Error occurred while changing user's password.");
    }
  };

  fetchUserEarnings = async ({ startDate, endDate }) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/earnings?startDate=${startDate}&endDate=${endDate}`);
      throwErrorIfWrongStatus(response, 200);
      const { daily, overview } = response.data.data;
      return { daily, overview };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching user's earnings.");
    }
  };

  handleWithdrawalMethods = async body => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/withdrawal-method`, body);
      throwErrorIfWrongStatus(response, 200);
      const { withdrawalMethod } = response.data.data;
      return { withdrawalMethod };
    } catch (err) {
      throw handleError(err, 'Error occurred while handling withdrawal methods.');
    }
  };

  updateBasicInfo = async formData => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.post(`${BASE_URL}/update-basic-info`, formData, { headers });
      throwErrorIfWrongStatus(response, 200);
      const { user } = response.data.data;
      return { user };
    } catch (err) {
      throw handleError(err, 'Error occurred while updating basic info.');
    }
  };
}

export default new UserService();
