import React, { useEffect, useRef } from 'react';
import { AuthContainer, Input } from '../../../components';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../../config';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Form, StyledButton } from './ResetPasswordPage.styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { notifyError } from '../../../utils/notify';
import { LOGIN } from '../../../components/App/routes';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../../store/slices/auth/asyncThunks';
import { selectAuthActionsPending } from '../../../store/slices/auth/slice';

export const resetPasswordFormSchema = yup
  .object({
    password: yup.string().trim().required('Password is required'),
  })
  .required();

const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recaptchaRef = useRef();
  const { resetPassword: resetPasswordPending } = useSelector(selectAuthActionsPending);
  const { state } = useLocation();
  const email = state?.email;
  const code = state?.code;

  useEffect(() => {
    if (!email || !code) {
      navigate(`/${LOGIN}`);
    }
  }, [email, code, navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
    },
    delayError: 300,
    resolver: yupResolver(resetPasswordFormSchema),
  });

  const onSubmit = async data => {
    const { password } = data;
    const recaptchaValue = await recaptchaRef.current.executeAsync();

    dispatch(resetPassword({ email, token: recaptchaValue, code, password }))
      .unwrap()
      .then(() => {
        navigate(`/${LOGIN}`);
      })
      .catch(err => {
        recaptchaRef.current.reset();
        notifyError(err.message);
      });
  };
  return (
    <AuthContainer title="Reset Password" subtitle="Enter new password">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="Enter new password"
          {...register('password')}
          error={errors.password?.message}
          type="password"
        />
        <StyledButton title="Continue" type="submit" isLoading={resetPasswordPending} />
      </Form>
      <ReCAPTCHA ref={recaptchaRef} sitekey={config.googleRecaptcha.key} size="invisible" />
    </AuthContainer>
  );
};

export default ResetPasswordPage;
