import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { selectIsLoggedIn } from '../../store/slices/auth/slice';
import { Navigation } from '../../components';
import { ContentContainer, Container } from './PrivateLayout.styles';
import { LOGIN } from '../../components/App/routes';

const PrivateLayout = ({ hideNavigation }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to={`/${LOGIN}`} state={{ from: location }} replace />;
  }

  return (
    <Container $hideNavigation={hideNavigation}>
      {!hideNavigation && <Navigation />}
      <ContentContainer $hideNavigation={hideNavigation}>
        <Outlet />
      </ContentContainer>
    </Container>
  );
};

export default PrivateLayout;
