import styled from 'styled-components';
import { Text14Regular, Text32ExtraBold } from '../../utils/Text/Text.styles';

export const Container = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: 900px) {
    justify-content: center;
  }
`;

export const LeftSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 4.2rem;
  align-items: center;
  max-width: 72rem;

  svg {
    @media only screen and (max-width: 500px) {
      margin-bottom: 10rem;
    }
  }
`;

export const RightSide = styled.div`
  flex: 1;
  max-width: 72rem;
  overflow: hidden;

  img {
    max-width: 72rem;
    max-height: 100%;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-bottom: 10rem;
  justify-content: center;
  width: 36rem;

  @media only screen and (max-width: 500px) {
    width: 100%;
    padding: 0 1.6rem;
    margin-bottom: 2rem;
  }
`;

export const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4.8rem;
`;

export const Subtitle = styled(Text14Regular)`
  margin-top: 0.6rem;
  text-align: center;
`;

export const Title = styled(Text32ExtraBold)`
  letter-spacing: -0.96px;
  text-align: center;
`;
