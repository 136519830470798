import React, { useState, forwardRef } from 'react';
import ErrorContainer from '../../ErrorContainer/ErrorContainer';
import {
  StyledLabel,
  StyledInput,
  VisibilityButton,
  LabelText,
  StyledTextarea,
  LeftIconContainer,
  RightIconContainer,
  Loader,
} from './Input.styles';
import { ReactComponent as VisibilityIcon } from '../../../../assets/icons/visibility.svg';
import { ReactComponent as VisibilityOffIcon } from '../../../../assets/icons/visibility_off.svg';
import LoaderGIF from '../../../../assets/gifs/loader.gif';

const Input = forwardRef(
  (
    {
      defaultValue,
      value,
      placeholder,
      onChange,
      autoFocus,
      disabled,
      className,
      error,
      hideErrorMessage,
      label,
      type,
      twoLineError,
      leftIcon,
      rightIcon,
      rightIconLoader,
      onRightIconClick,
      ...inputProps
    },
    ref,
  ) => {
    const [isShown, setIsShown] = useState(false);
    const [focused, setFocused] = useState(false);

    const onFocus = () => setFocused(true);
    const onBlur = () => {
      setFocused(false);
    };

    return (
      <StyledLabel disabled={disabled} className={className}>
        {label && <LabelText>{label}</LabelText>}
        {leftIcon && <LeftIconContainer $labelExists={!!label}>{leftIcon}</LeftIconContainer>}
        {type === 'textarea' ? (
          <StyledTextarea
            ref={ref}
            placeholder={placeholder}
            type={type}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            autoFocus={autoFocus}
            disabled={disabled}
            onFocus={onFocus}
            onBlurCapture={onBlur}
            {...inputProps}
          />
        ) : (
          <StyledInput
            ref={ref}
            placeholder={placeholder}
            type={type === 'password' ? (isShown ? 'text' : 'password') : type}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            autoFocus={autoFocus}
            disabled={disabled}
            onFocus={onFocus}
            onBlurCapture={onBlur}
            $isShown={isShown}
            $isFocused={focused}
            $leftIcon={!!leftIcon}
            $rightIcon={!!rightIcon}
            autoComplete="new-password"
            {...inputProps}
          />
        )}
        {rightIcon && (
          <RightIconContainer onClick={onRightIconClick} $labelExists={!!label} $rightIconLoader={!!rightIconLoader}>
            {rightIconLoader ? <Loader src={LoaderGIF} /> : rightIcon}
          </RightIconContainer>
        )}
        {type === 'password' && (
          <VisibilityButton onClick={() => setIsShown(prev => !prev)} $labelExists={!!label}>
            {isShown ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </VisibilityButton>
        )}
        {!hideErrorMessage && <ErrorContainer errorText={error} twoLine={twoLineError} />}
      </StyledLabel>
    );
  },
);

export default Input;
