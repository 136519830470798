import styled from 'styled-components';
import { Text15Light } from '../../utils/Text/Text.styles';

export const Form = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-top: 3.2rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  align-items: center;
  margin-top: auto;
`;

export const CancelText = styled(Text15Light)`
  color: #c4a0ff;
  text-decoration: underline;
  cursor: pointer;
`;
