import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/d/payment';

class PaymentService {
  createPaymentSession = async ({ address, city, zip, country, state }) => {
    try {
      let body = {
        address,
        city,
        zip,
        country,
        ...(state && { state }),
      };

      const response = await axiosApiClient.post(`${BASE_URL}/create-session`, body);
      throwErrorIfWrongStatus(response, 200);
      const { publicToken } = response.data.data;
      return { publicToken };
    } catch (err) {
      throw handleError(err, 'Error occurred while creating payment session');
    }
  };

  cancelSubscription = async () => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/cancel-subscription`, {});
      throwErrorIfWrongStatus(response, 200);
      const { canceledAt } = response.data.data;
      return { canceledAt };
    } catch (err) {
      throw handleError(err, 'Error occurred while canceling subscription');
    }
  };

  getPaymentSession = async ({ sessionId }) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/session?id=${sessionId}`);
      throwErrorIfWrongStatus(response, 200);
      const { paymentSession, userData } = response.data.data;
      return { paymentSession, userData };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching payment session');
    }
  };
}

export default new PaymentService();
