import { createGlobalStyle } from 'styled-components';
import { theme } from '../styles/constants';

const styled = { createGlobalStyle };

const GlobalStyles = styled.createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    height: 100%;
    font-size: 62.5%; // 1rem = 10px, 10/16 = 60.25%
    box-sizing: border-box;
  }

  body {
    height: 100%;
    font-weight: 400;
    font-size: 1.7rem;
    font-family: Lexend, sans-serif;
    line-height: normal;
    margin: 0;
    color: ${theme.colors.textColor};
    background-color: ${theme.colors.white};
  }

  input,
  select,
  textarea,
  button {
    font-family: inherit;
  }

  a,
  p,
  svg,
  button,
  div {
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  #root {
    height: 100%;
  }

  p + p {
    margin-top: 0;
  }

  .success-toast-message {
    background-color: #26134e;
    color: #fff;
    border-radius: 1.2rem;
  }

  .success-toast-message-progress {
    background-color: #843efe;
  }

  .rs-picker-daterange-panel {
    padding: 1.4rem 1.6rem;
  }

  .rs-picker-popup {
    border-radius: 16px;
    border: 1px solid #f2f2f2;
    background: #fff;
  }

  .rs-picker-daterange-header {
    border-bottom: none;
  }

  .rs-picker-popup.rs-picker-popup-daterange .rs-calendar:first-child {
    border-right: none;
  }

  .rs-picker-toolbar {
    border-top: none;
    padding: 0;

    .rs-btn-primary {
      visibility: hidden;
      &::after {
        color: white;
        content: 'Apply';
        border: 1px solid #4e2d94;
        background: linear-gradient(93deg, #9a69f5 3.61%, #582ea2 97.21%);
        visibility: visible;
        padding: 0.5rem 1rem;
        border-radius: 100px;
        font-weight: 500;
      }
    }
  }

  .rs-btn-subtle {
    color: ${theme.colors.textColor};

    &:hover {
      background: transparent;
      color: ${theme.colors.textColor};
    }
  }

  .rs-calendar-header-title {
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 4px;
  }

  .rs-btn-icon.rs-btn-xs > .rs-icon {
    font-size: 2.4rem;
  }
  .rs-btn-subtle:focus {
    background: transparent;
    color: ${theme.colors.textColor};
  }

  .rs-calendar-month-dropdown {
    border-radius: 16px;
    border: 1px solid #2c1655;
    background: #fff;
  }

  .rs-calendar-btn-close {
    background: #fff;
  }

  .rs-picker-daterange-predefined {
    height: unset;

    .rs-stack-item {
      width: 16rem;

      @media only screen and (max-width: 800px) {
        width: 100%;
      }

      .rs-btn-link {
        color: #bc9eff;

        &:hover {
          color: ${theme.colors.textColor};
          text-decoration-line: none;
        }
      }
    }
  }

  .rs-picker-toolbar-ranges {
    .rs-stack-item {
      @media only screen and (max-width: 800px) {
        width: 8.5rem;
      }
    }
  }

  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background: #9e74f9;

    &:hover {
      background: #9e74f9;
    }
  }

  .rs-calendar-table-cell-in-range:before {
  }

  .rs-picker-popup .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
    background-color: #9e74f9;
    color: ${theme.colors.white};
  }

  .rs-calendar-table-header-cell-content {
    color: ${theme.colors.textColor};
    font-size: 1.4rem;
  }

  .rs-calendar-table-cell {
    &:hover {
      color: ${theme.colors.textColor};
    }
  }

  .rs-picker-popup .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    color: ${theme.colors.textColor};
  }

  .rs-calendar-table-cell-un-same-month {
    visibility: hidden;
  }
`;

export default GlobalStyles;
