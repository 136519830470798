import React, { useEffect, useRef, useState } from 'react';
import { AuthContainer, ErrorContainer, OtpInput, Spinner } from '../../../components';
import { notifyError } from '../../../utils/notify';
import {
  Content,
  OtpContainer,
  ResendCodeContainer,
  ResendText,
  StyledButton,
  TimeRemainText,
} from './EmailVerificationPage.styles';
import { Text15Light } from '../../../components/utils/Text/Text.styles';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../../config';
import { checkVerificationCode, requestVerificationCode, verifyEmail } from '../../../store/slices/auth/asyncThunks';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { LOGIN, RESET_PASSWORD } from '../../../components/App/routes';
import { VERIFICATION_CODE_TYPES } from '../../../utils/constants';
import { selectAuthActionsPending } from '../../../store/slices/auth/slice';

const EmailVerificationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    checkVerificationCode: checkVerificationCodePending,
    verifyEmail: verifyEmailPending,
    requestVerificationCode: requestVerificationCodePending,
  } = useSelector(selectAuthActionsPending);
  const recaptchaRef = useRef();
  const [emailOtp, setEmailOtp] = useState([]);
  const [otpError, setOtpError] = useState('');
  const [timeRemaining, setTimeRemaining] = useState(59);
  const { state } = useLocation();
  const email = state?.email;
  const type = state?.type;

  useEffect(() => {
    if (!email || !type) {
      navigate(`/${LOGIN}`);
    }
  }, [email, type, navigate]);

  useEffect(() => {
    if (timeRemaining > 0) {
      const timer = setTimeout(() => {
        setTimeRemaining(timeRemaining - 1);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [timeRemaining]);

  const onChangeOtpInput = () => {
    setOtpError('');
  };

  const resendCode = async () => {
    if (timeRemaining > 0) {
      notifyError('You can resend code after 1 minute.');
      return;
    } else {
      const recaptchaValue = await recaptchaRef.current.executeAsync();

      dispatch(requestVerificationCode({ email, token: recaptchaValue, type }))
        .unwrap()
        .then(() => {
          setTimeRemaining(59);
        })
        .catch(err => {
          recaptchaRef.current.reset();
          notifyError(err.message);
        });
    }
  };

  useEffect(() => {
    const handleVerifyEmail = async () => {
      if (emailOtp.join('').length === 6 && email) {
        const recaptchaValue = await recaptchaRef.current.executeAsync();

        if (type === VERIFICATION_CODE_TYPES.FORGOT_PASSWORD) {
          dispatch(checkVerificationCode({ email, code: emailOtp.join(''), token: recaptchaValue }))
            .unwrap()
            .then(() => {
              navigate(`/${RESET_PASSWORD}`, { state: { email, code: emailOtp.join('') } });
            })
            .catch(err => {
              recaptchaRef.current.reset();
              notifyError(err.message);
            });
        } else {
          dispatch(verifyEmail({ email, code: emailOtp.join(''), token: recaptchaValue }))
            .unwrap()
            .then(() => {})
            .catch(err => {
              recaptchaRef.current.reset();
              notifyError(err.message);
            });
        }
      }
    };

    handleVerifyEmail();
  }, [emailOtp, dispatch, email, type, navigate]);

  return (
    <AuthContainer title="Verification Code" subtitle={`Verification code sent to ${email}`}>
      <OtpContainer>
        <OtpInput codeLength={6} otp={emailOtp} setOtp={setEmailOtp} autoFocus onChange={onChangeOtpInput} />
        <ErrorContainer errorText={otpError} />
        <Content>
          <ResendCodeContainer>
            <Text15Light>Didn’t get the code?</Text15Light>
            {requestVerificationCodePending ? (
              <Spinner height={19} width={52} />
            ) : (
              <ResendText $disabled={timeRemaining > 0} onClick={resendCode}>
                Resend
              </ResendText>
            )}
          </ResendCodeContainer>
          {timeRemaining > 0 && (
            <TimeRemainText>00:{timeRemaining < 10 ? `0${timeRemaining}` : timeRemaining}</TimeRemainText>
          )}
        </Content>
        <StyledButton title="Continue" isLoading={checkVerificationCodePending || verifyEmailPending} />
      </OtpContainer>
      <ReCAPTCHA ref={recaptchaRef} sitekey={config.googleRecaptcha.key} size="invisible" />
    </AuthContainer>
  );
};

export default EmailVerificationPage;
