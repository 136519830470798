import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/d/influencer-requests';

class InfluencerRequestService {
  createInfluencerRequestPassword = async ({ password }) => {
    try {
      const body = {
        password,
      };
      const response = await axiosApiClient.put(`${BASE_URL}/update-password`, body);
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, "Error occurred while setting user's password.");
    }
  };

  redeemInvitationCode = async ({ code }) => {
    try {
      const body = {
        code,
      };
      const response = await axiosApiClient.post(`${BASE_URL}/redeem-invitation-code`, body);
      throwErrorIfWrongStatus(response, 200);
      const { user } = response.data.data;
      return { user };
    } catch (err) {
      throw handleError(err, 'Error occurred while redeem invitation code.');
    }
  };

  updateBasicInfo = async formData => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.post(`${BASE_URL}/update-basic-info`, formData, { headers });
      throwErrorIfWrongStatus(response, 200);
      const { user } = response.data.data;
      return { user };
    } catch (err) {
      throw handleError(err, 'Error occurred while updating basic info.');
    }
  };

  uploadPhoto = async (formData, onUploadProgress) => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.post(`${BASE_URL}/upload-photo`, formData, {
        headers,
        onUploadProgress,
      });
      throwErrorIfWrongStatus(response, 200);
      const { userStatus } = response.data.data;
      return { userStatus };
    } catch (err) {
      throw handleError(err, 'Error occurred while uploading photo.');
    }
  };

  uploadVideo = async (formData, onUploadProgress) => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.post(`${BASE_URL}/upload-video`, formData, {
        headers,
        onUploadProgress,
      });
      throwErrorIfWrongStatus(response, 200);
      const { userStatus } = response.data.data;
      return { userStatus };
    } catch (err) {
      throw handleError(err, 'Error occurred while uploading video.');
    }
  };

  uploadAudio = async (formData, onUploadProgress) => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.post(`${BASE_URL}/upload-audio`, formData, {
        headers,
        onUploadProgress,
      });
      throwErrorIfWrongStatus(response, 200);
      const { userStatus } = response.data.data;
      return { userStatus };
    } catch (err) {
      throw handleError(err, 'Error occurred while uploading audio.');
    }
  };

  getOrCreateIdVerificationToken = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/verification`);
      throwErrorIfWrongStatus(response, 200);
      const { token } = response.data.data;
      return { token };
    } catch (err) {
      throw handleError(err, 'Error occurred while getting identify verification token.');
    }
  };

  getQuestionnaire = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/questionnaire`);
      throwErrorIfWrongStatus(response, 200);
      const { questionnaire } = response.data.data;
      return { questionnaire };
    } catch (err) {
      throw handleError(err, 'Error occurred while getting questionnaire.');
    }
  };

  acceptAgreement = async () => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/accept-agreement`);
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, 'Error occurred while accepting agreement.');
    }
  };
}

export default new InfluencerRequestService();
