import React from 'react';
import { PageContainer } from './SplashPage.styles';
import Lottie from 'lottie-react';
import logoAnimation from '../../../assets/gifs/logo.json';

const SplashPage = () => {
  return (
    <PageContainer>
      <Lottie animationData={logoAnimation} loop />
    </PageContainer>
  );
};

export default SplashPage;
