import styled from 'styled-components';

export const NavigationWrapper = styled.div`
  position: fixed;
  bottom: 0;
  top: 0;
  padding: 2.4rem 3.2rem;
  width: 22rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #eeecfb;
  background: #f5f5fd;

  @media only screen and (max-width: 1456px) {
    padding-left: 1.6rem;
  }

  @media only screen and (max-width: 768px) {
    display: ${({ $hidden }) => ($hidden ? 'none' : 'block')};
    position: fixed;
    width: unset;
    top: unset;
    right: 0;
    left: 0;
    border: none;
    height: 5.6rem;
    z-index: 10;
    padding: unset;
    border-right: unset;
  }
`;

export const NavigationContainer = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: row;
    height: 100%;
    padding: 0 6.4rem;
    align-items: center;
    border-top: 1px solid #eeecfb;
    background: #f5f5fd;
  }
`;

export const NavigationItem = styled.div`
  font-size: 1.4rem;
  font-weight: 300;
  line-height: normal;
  a {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #54329e;
    text-decoration: none;
    padding: 0.8rem 1.2rem;
    border: 1px solid transparent;

    svg {
      color: #54329e;
    }
  }

  a.active {
    color: #170c2f;
    font-weight: 400;
    border-radius: 12px;
    border-color: #eeecfb;
    background: #fff;
    box-shadow: 4px 5px 2px 0px rgba(0, 0, 0, 0), 2px 3px 2px 0px rgba(0, 0, 0, 0.01);

    svg {
      color: #170c2f;
    }
  }
`;

export const NavigationItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  @media only screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

export const NavigationFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavLinkText = styled.span`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavLinkIconWrapper = styled.div`
  height: 2.7rem;
`;

export const LogoWrapper = styled.div`
  margin-bottom: 2.4rem;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const FooterButton = styled.button`
  border-radius: 17px;

  background: #fff;
  width: 15.6rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  font-size: 1.4rem;
  cursor: pointer;
  font-weight: 300;
  padding: 0.6rem 0.6rem 0.6rem 0;
`;
