import React, { useState } from 'react';
import {
  Container,
  EmptyDiv,
  Header,
  IconWrapper,
  MethodsContainer,
  NoteText,
  StyledButton,
} from './WithdrawPage.styles';
import { Text16Bold, Text20Bold } from '../../components/utils/Text/Text.styles';
import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/chevron_left.svg';
import { NumericInput, WithdrawalMethod } from '../../components';
import { useNavigate } from 'react-router-dom';
import { WALLET, WITHDRAWAL_METHOD } from '../../components/App/routes';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/user/slice';
import { WITHDRAWAL_METHOD_TYPE } from '../../utils/constants';
import { selectWithdrawalActionsPending } from '../../store/slices/withdrawal/slice';
import { requestWithdrawal } from '../../store/slices/withdrawal/asyncThunks';
import { notifyError } from '../../utils/notify';

const WithdrawPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { requestWithdrawal: requestWithdrawalPending } = useSelector(selectWithdrawalActionsPending);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [amount, setAmount] = useState('');

  const { crypto, domesticWire, internationalWire } = user.withdrawalMethod;

  const onMethodClick = type => {
    const hasMethod = {
      [WITHDRAWAL_METHOD_TYPE.CRYPTO]: !!crypto,
      [WITHDRAWAL_METHOD_TYPE.DOMESTIC_WIRE]: !!domesticWire,
      [WITHDRAWAL_METHOD_TYPE.INTERNATIONAL_WIRE]: !!internationalWire,
    };

    if (hasMethod[type]) {
      setSelectedMethod(type);
    } else {
      navigate(`/${WITHDRAWAL_METHOD}`, { state: { type } });
    }
  };

  const onEditClick = type => {
    navigate(`/${WITHDRAWAL_METHOD}`, { state: { type } });
  };

  const submitWithdrawalRequest = () => {
    dispatch(requestWithdrawal({ withdrawalMethodType: selectedMethod, amount }))
      .unwrap()
      .then(() => {
        navigate(`/${WALLET}`);
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <Container>
      <Header>
        <IconWrapper onClick={() => navigate(`/${WALLET}`)}>
          <ChevronLeftIcon />
        </IconWrapper>
        <Text20Bold>Withdraw Money</Text20Bold>
        <EmptyDiv />
      </Header>
      <NoteText>
        Your balance can be withdrawn 21 days after it’s earned. You can request up to two withdrawals every month.
      </NoteText>
      <Text16Bold>Select Method</Text16Bold>
      <MethodsContainer>
        <WithdrawalMethod
          title="Domestic Wire"
          description="Withdraw to a US bank account."
          note="Arriving in 5-7 business days."
          isSelected={selectedMethod === WITHDRAWAL_METHOD_TYPE.DOMESTIC_WIRE}
          hasEdit={!!domesticWire}
          onClick={() => onMethodClick(WITHDRAWAL_METHOD_TYPE.DOMESTIC_WIRE)}
          onEditClick={() => onEditClick(WITHDRAWAL_METHOD_TYPE.DOMESTIC_WIRE)}
        />
        <WithdrawalMethod
          title="International Wire"
          description="Withdraw to an international (non US) bank account."
          note="Arriving in 5-7 business days."
          isSelected={selectedMethod === WITHDRAWAL_METHOD_TYPE.INTERNATIONAL_WIRE}
          hasEdit={!!internationalWire}
          onClick={() => onMethodClick(WITHDRAWAL_METHOD_TYPE.INTERNATIONAL_WIRE)}
          onEditClick={() => onEditClick(WITHDRAWAL_METHOD_TYPE.INTERNATIONAL_WIRE)}
        />
        <WithdrawalMethod
          title="Crypto"
          description="Withdraw in cryptocurrency."
          note="Arriving in 1-2 business days."
          isSelected={selectedMethod === WITHDRAWAL_METHOD_TYPE.CRYPTO}
          hasEdit={!!crypto}
          onClick={() => onMethodClick(WITHDRAWAL_METHOD_TYPE.CRYPTO)}
          onEditClick={() => onEditClick(WITHDRAWAL_METHOD_TYPE.CRYPTO)}
        />
      </MethodsContainer>
      <NumericInput label="Amount" placeholder="Enter amount" onChange={e => setAmount(e.target.value)} />
      <StyledButton
        title="Submit"
        disabled={!selectedMethod || !amount.trim()}
        onClick={submitWithdrawalRequest}
        isLoading={requestWithdrawalPending}
      />
    </Container>
  );
};

export default WithdrawPage;
