import {
  acceptAgreement,
  createInfluencerRequestPassword,
  getOrCreateIdVerificationToken,
  getQuestionnaire,
  redeemInvitationCode,
  updateBasicInfo,
} from './asyncThunks';

export const createInfluencerRequestPasswordBuilder = builder => {
  builder.addCase(createInfluencerRequestPassword.pending, state => {
    state.pending.createInfluencerRequestPassword = true;
  });
  builder.addCase(createInfluencerRequestPassword.fulfilled, state => {
    state.pending.createInfluencerRequestPassword = false;
  });
  builder.addCase(createInfluencerRequestPassword.rejected, state => {
    state.pending.createInfluencerRequestPassword = false;
  });
};

export const redeemInvitationCodeBuilder = builder => {
  builder.addCase(redeemInvitationCode.pending, state => {
    state.pending.redeemInvitationCode = true;
  });
  builder.addCase(redeemInvitationCode.fulfilled, state => {
    state.pending.redeemInvitationCode = false;
  });
  builder.addCase(redeemInvitationCode.rejected, state => {
    state.pending.redeemInvitationCode = false;
  });
};

export const updateBasicInfoBuilder = builder => {
  builder.addCase(updateBasicInfo.pending, state => {
    state.pending.updateBasicInfo = true;
  });
  builder.addCase(updateBasicInfo.fulfilled, state => {
    state.pending.updateBasicInfo = false;
  });
  builder.addCase(updateBasicInfo.rejected, state => {
    state.pending.updateBasicInfo = false;
  });
};

export const getOrCreateIdVerificationTokenBuilder = builder => {
  builder.addCase(getOrCreateIdVerificationToken.pending, state => {
    state.pending.getOrCreateIdVerificationToken = true;
  });
  builder.addCase(getOrCreateIdVerificationToken.fulfilled, state => {
    state.pending.getOrCreateIdVerificationToken = false;
  });
  builder.addCase(getOrCreateIdVerificationToken.rejected, state => {
    state.pending.getOrCreateIdVerificationToken = false;
  });
};

export const getQuestionnaireBuilder = builder => {
  builder.addCase(getQuestionnaire.fulfilled, (state, { payload }) => {
    state.questionnaire = payload.questionnaire;
  });
};

export const acceptAgreementBuilder = builder => {
  builder.addCase(acceptAgreement.pending, state => {
    state.pending.acceptAgreement = true;
  });
  builder.addCase(acceptAgreement.fulfilled, state => {
    state.pending.acceptAgreement = false;
  });
  builder.addCase(acceptAgreement.rejected, state => {
    state.pending.acceptAgreement = false;
  });
};
