import { cancelSubscription, createPaymentSession, getPaymentSession } from './asyncThunk';

export const createPaymentSessionBuilder = builder => {
  builder.addCase(createPaymentSession.pending, state => {
    state.pending.createPaymentSession = true;
  });
  builder.addCase(createPaymentSession.fulfilled, state => {
    state.pending.createPaymentSession = false;
  });
  builder.addCase(createPaymentSession.rejected, state => {
    state.pending.createPaymentSession = false;
  });
};

export const cancelSubscriptionBuilder = builder => {
  builder.addCase(cancelSubscription.pending, state => {
    state.pending.cancelSubscription = true;
  });
  builder.addCase(cancelSubscription.fulfilled, state => {
    state.pending.cancelSubscription = false;
  });
  builder.addCase(cancelSubscription.rejected, state => {
    state.pending.cancelSubscription = false;
  });
};

export const getPaymentSessionBuilder = builder => {
  builder.addCase(getPaymentSession.pending, state => {
    state.pending.getPaymentSession = true;
  });
  builder.addCase(getPaymentSession.fulfilled, state => {
    state.pending.getPaymentSession = false;
  });
  builder.addCase(getPaymentSession.rejected, state => {
    state.pending.getPaymentSession = false;
  });
};
