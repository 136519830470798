const config = {
  environment: process.env.NODE_ENV,
  api: {
    baseUrl: process.env.REACT_APP_API_URL,
  },
  googleRecaptcha: {
    key: process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY,
  },
  intercom: {
    appId: process.env.REACT_APP_INTERCOM_APP_ID,
  },
};

export default config;
