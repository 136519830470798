import { fetchUserInfo } from '../user/asyncThunks';
import { checkVerificationCode, login, requestVerificationCode, resetPassword, verifyEmail } from './asyncThunks';

export const loginBuilder = builder => {
  builder.addCase(login.pending, state => {
    state.pending.login = true;
  });
  builder.addCase(login.fulfilled, (state, action) => {
    const { accessToken } = action.payload;
    state.pending.login = false;
    state.isLoggedIn = true;
    state.accessToken = accessToken;
  });
  builder.addCase(login.rejected, state => {
    state.pending.login = false;
  });
};

export const fetchUserInfoBuilder = builder => {
  builder.addCase(fetchUserInfo.fulfilled, (state, { payload }) => {
    const { accessToken } = payload;
    state.isLoggedIn = true;
    state.accessToken = accessToken;
  });
  builder.addCase(fetchUserInfo.rejected, state => {
    state.isLoggedIn = false;
  });
};

export const requestVerificationCodeBuilder = builder => {
  builder.addCase(requestVerificationCode.pending, state => {
    state.pending.requestVerificationCode = true;
  });
  builder.addCase(requestVerificationCode.fulfilled, state => {
    state.pending.requestVerificationCode = false;
  });
  builder.addCase(requestVerificationCode.rejected, state => {
    state.pending.requestVerificationCode = false;
  });
};

export const checkVerificationCodeBuilder = builder => {
  builder.addCase(checkVerificationCode.pending, state => {
    state.pending.checkVerificationCode = true;
  });
  builder.addCase(checkVerificationCode.fulfilled, state => {
    state.pending.checkVerificationCode = false;
  });
  builder.addCase(checkVerificationCode.rejected, state => {
    state.pending.checkVerificationCode = false;
  });
};

export const resetPasswordBuilder = builder => {
  builder.addCase(resetPassword.pending, state => {
    state.pending.resetPassword = true;
  });
  builder.addCase(resetPassword.fulfilled, state => {
    state.pending.resetPassword = false;
  });
  builder.addCase(resetPassword.rejected, state => {
    state.pending.resetPassword = false;
  });
};

export const verifyEmailBuilder = builder => {
  builder.addCase(verifyEmail.pending, state => {
    state.pending.verifyEmail = true;
  });
  builder.addCase(verifyEmail.fulfilled, (state, action) => {
    const { accessToken } = action.payload;
    state.pending.verifyEmail = false;
    state.isLoggedIn = true;
    state.accessToken = accessToken;
  });
  builder.addCase(verifyEmail.rejected, state => {
    state.pending.verifyEmail = false;
  });
};
