import styled from 'styled-components';
import { Text24Bold } from '../../../components/utils/Text/Text.styles';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(Text24Bold)`
  margin-top: 1.6rem;
`;
