import React from 'react';
import { Input, LogoComponent, OnboardingContainer } from '../../../components';
import { Content, Form, StyledButton, Title } from './UpdatePasswordPage.styles';
import { notifyError } from '../../../utils/notify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { createInfluencerRequestPassword } from '../../../store/slices/influencerRequest/asyncThunks';
import { useDispatch, useSelector } from 'react-redux';
import { selectInfluencerRequestActionsPending } from '../../../store/slices/influencerRequest/slice';

export const updatePasswordFormSchema = yup
  .object({
    password: yup.string().trim().required('Password is required'),
  })
  .required();

const UpdatePasswordPage = () => {
  const { createInfluencerRequestPassword: createInfluencerRequestPasswordPending } = useSelector(
    selectInfluencerRequestActionsPending,
  );
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
    },
    delayError: 300,
    resolver: yupResolver(updatePasswordFormSchema),
  });

  const onSubmit = async data => {
    const { password } = data;
    dispatch(createInfluencerRequestPassword({ password }))
      .unwrap()
      .then(() => {})
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <OnboardingContainer hideHeader>
      <Content>
        <LogoComponent />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title>Create a password</Title>
          <Input
            placeholder="Enter password"
            {...register('password')}
            error={errors.password?.message}
            type="password"
          />
          <StyledButton title="Continue" type="submit" isLoading={createInfluencerRequestPasswordPending} />
        </Form>
      </Content>
    </OnboardingContainer>
  );
};

export default UpdatePasswordPage;
