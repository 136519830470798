import styled from 'styled-components';

export const Container = styled.div`
  max-width: 45rem;
  width: 100%;
  margin-inline: auto;
  margin-top: 7rem;

  @media only screen and (max-width: 768px) {
    margin-top: 0;
    padding: 2.4rem 2.2rem;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
`;

export const IconWrapper = styled.div`
  height: 2.4rem;
  width: 2.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyDiv = styled.div`
  width: 2.4rem;
`;
