import styled from 'styled-components';
import { Text14Bold, Text14Light } from '../utils/Text/Text.styles';

export const Card = styled.div`
  border-radius: 12px;
  border: 1px solid #c5bef4;
  background: #fff;
  padding: 1.6rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

export const Text = styled(Text14Light)`
  letter-spacing: -0.408px;
  opacity: 0.6;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const EditText = styled(Text14Bold)`
  cursor: pointer;
  text-decoration: underline;
`;
