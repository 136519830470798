import React, { useEffect, useState } from 'react';
import { OnboardingContainer, UploadAudio, UploadPhotos, UploadVideos } from '../../../components';
import { Content, HeaderContainer } from './UploadMediaPage.styles';
import { Text20Bold } from '../../../components/utils/Text/Text.styles';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/slices/user/slice';
import { MIN_NUM_OF_AUDIOS, MIN_NUM_OF_PHOTOS, MIN_NUM_OF_VIDEOS } from '../../../utils/constants';

const STEP_MODE = {
  PHOTO: 'PHOTO',
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
};

const UploadMediaPage = () => {
  const user = useSelector(selectUser);
  const [mode, setMode] = useState(null);

  useEffect(() => {
    if (user.numOfUploadedPhotos < MIN_NUM_OF_PHOTOS) {
      setMode(STEP_MODE.PHOTO);
    } else if (user.numOfUploadedVideos < MIN_NUM_OF_VIDEOS) {
      setMode(STEP_MODE.VIDEO);
    } else if (user.numOfUploadedAudios < MIN_NUM_OF_AUDIOS) {
      setMode(STEP_MODE.AUDIO);
    }
  }, [user]);

  const renderContent = () => {
    switch (mode) {
      case STEP_MODE.PHOTO:
        return <UploadPhotos />;
      case STEP_MODE.VIDEO:
        return <UploadVideos />;
      case STEP_MODE.AUDIO:
        return <UploadAudio />;
      default:
        break;
    }
  };

  return (
    <OnboardingContainer>
      <Content>
        <HeaderContainer>
          <Text20Bold>Gallery</Text20Bold>
        </HeaderContainer>
        {renderContent()}
      </Content>
    </OnboardingContainer>
  );
};

export default UploadMediaPage;
