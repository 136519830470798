import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SUBSCRIBE } from '../../../components/App/routes';
import { Input, OnboardingContainer, PaymentInfoModal, Select } from '../../../components';
import {
  BackContainer,
  Content,
  EmptyDiv,
  Form,
  InputsRow,
  StyledButton,
  Title,
  TitleContainer,
} from './CheckoutPage.styles';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { COUNTRIES, USA_STATES } from '../../../utils/countries';
import { getVatByCountry, roundNumber } from '../../../utils/util';
import { useDispatch, useSelector } from 'react-redux';
import { notifyError } from '../../../utils/notify';
import { createPaymentSession } from '../../../store/slices/payment/asyncThunk';
import { selectPaymentActionsPending } from '../../../store/slices/payment/slice';
import { ReactComponent as BackIcon } from '../../../assets/icons/chevron_left.svg';

const USA_COUNTRY = 'United States of America';

const enterBillingAddressFormSchema = yup
  .object({
    address: yup.string().trim().required('Address is required'),
    city: yup.string().trim().required('City is required'),
    zip: yup.string().trim().required('Zip is required'),
    country: yup.mixed().required('Country is required'),
    state: yup
      .object()
      .nullable()
      .when('country', {
        is: value => {
          return value?.label === USA_COUNTRY;
        },
        then: schema => schema.required('State is required'),
      }),
  })
  .required();

const CheckoutPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createPaymentSession: createPaymentSessionPending } = useSelector(selectPaymentActionsPending);

  const [token, setToken] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      address: '',
      city: '',
      zip: '',
      country: null,
      state: null,
    },
    delayError: 300,
    resolver: yupResolver(enterBillingAddressFormSchema),
  });

  const watchCountry = watch('country');

  const onSubmitBillingAddress = data => {
    const { address, city, zip, country, state } = data;
    setSelectedCountry(country.value.alpha3);

    dispatch(
      createPaymentSession({
        address,
        city,
        zip,
        country: country.value,
        state: country.label === USA_COUNTRY ? state?.value : null,
      }),
    )
      .unwrap()
      .then(({ publicToken }) => {
        setShowModal(true);
        setToken(publicToken);
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  const [price, vat, priceWithVat] = useMemo(() => {
    const price = 14.99;

    if (selectedCountry) {
      const vat = getVatByCountry(selectedCountry);
      const priceWithVat = roundNumber(price + (vat / 100) * price, 2);
      return [price, vat, priceWithVat];
    } else {
      return [price, 0, price];
    }
  }, [selectedCountry]);

  const countryOptions = useMemo(() => {
    return COUNTRIES.map(c => ({ value: c, label: c.name }));
  }, []);

  const stateOptions = useMemo(() => {
    return USA_STATES.map(state => ({ value: state, label: state }));
  }, []);

  useEffect(() => {
    setValue(
      'country',
      countryOptions.find(c => c.label === USA_COUNTRY),
    );
  }, [setValue, countryOptions]);

  return (
    <OnboardingContainer>
      <Content>
        <TitleContainer>
          <BackContainer onClick={() => navigate(`/${SUBSCRIBE}`)}>
            <BackIcon />
          </BackContainer>
          <Title>Billing Address</Title>
          <EmptyDiv />
        </TitleContainer>
        <Form onSubmit={handleSubmit(onSubmitBillingAddress)}>
          <Input label="Address" placeholder="Enter address" {...register('address')} error={errors.address?.message} />
          <Input label="City" placeholder="Enter city" {...register('city')} error={errors.city?.message} />
          <InputsRow>
            <Input label="ZIP Code" placeholder="ZIP" type="tel" {...register('zip')} error={errors.zip?.message} />
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Select
                  selectedOption={field.value}
                  handleChange={value => field.onChange(value)}
                  options={countryOptions}
                  label="Country"
                  error={errors.country?.message}
                />
              )}
            />
          </InputsRow>
          {watchCountry?.label === USA_COUNTRY && (
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <Select
                  selectedOption={field.value}
                  handleChange={value => field.onChange(value)}
                  options={stateOptions}
                  label="State"
                  error={errors.state?.message}
                />
              )}
            />
          )}
          <StyledButton title="Continue" isLoading={createPaymentSessionPending} />
        </Form>
      </Content>
      <PaymentInfoModal
        show={showModal}
        setShow={setShowModal}
        price={price}
        vat={vat}
        priceWithVat={priceWithVat}
        publicToken={token}
      />
    </OnboardingContainer>
  );
};

export default CheckoutPage;
