import { createAsyncThunk } from '@reduxjs/toolkit';
import WithdrawalService from '../../../api/services/WithdrawalService';

export const fetchWithdrawals = createAsyncThunk('withdrawal/fetchWithdrawals', async ({ page }) => {
  return await WithdrawalService.fetchWithdrawals(page);
});

export const requestWithdrawal = createAsyncThunk('withdrawal/requestWithdrawal', async requestData => {
  return await WithdrawalService.requestWithdrawal(requestData);
});
