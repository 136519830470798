import styled from 'styled-components';
import { Text12Light, Text13Light, Text14Regular, Text20Regular } from '../../utils/Text/Text.styles';

export const Card = styled.div`
  height: 13rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 17px;
  border: 1px solid #f2f2f2;
  box-shadow: 4px 5px 2px 0px rgba(0, 0, 0, 0), 2px 3px 2px 0px rgba(0, 0, 0, 0.01);
  padding: 1.4rem;
  position: relative;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

export const ValueTextContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 0.4rem;
`;

export const PrefixText = styled(Text20Regular)`
  color: #c99aff;
  margin-bottom: 0.8rem;
`;

export const SuffixText = styled(Text14Regular)`
  color: #c99aff;
  margin-bottom: 0.4rem;
`;

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
`;

export const BottomText = styled(Text13Light)`
  color: #c99aff;
  letter-spacing: -0.42px;
`;

export const GrossValue = styled(Text12Light)`
  opacity: 0.5;
  margin-top: 0.2rem;
`;

export const HiddenGrossValue = styled(GrossValue)`
  opacity: 0;
`;
