import { fetchWithdrawals, requestWithdrawal } from './asyncThunks';

export const fetchWithdrawalsBuilder = builder => {
  builder.addCase(fetchWithdrawals.pending, state => {
    state.fetchWithdrawalsInfo.pending = true;
  });
  builder.addCase(fetchWithdrawals.fulfilled, (state, { payload }) => {
    const { data, total } = payload;
    state.fetchWithdrawalsInfo.pending = false;
    state.fetchWithdrawalsInfo.data = data;
    state.fetchWithdrawalsInfo.total = total;
  });
  builder.addCase(fetchWithdrawals.rejected, state => {
    state.fetchWithdrawalsInfo.pending = false;
  });
};

export const requestWithdrawalBuilder = builder => {
  builder.addCase(requestWithdrawal.pending, state => {
    state.pending.requestWithdrawal = true;
  });
  builder.addCase(requestWithdrawal.fulfilled, (state, { payload }) => {
    state.pending.requestWithdrawal = false;
  });
  builder.addCase(requestWithdrawal.rejected, state => {
    state.pending.requestWithdrawal = false;
  });
};
