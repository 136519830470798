import { createAsyncThunk } from '@reduxjs/toolkit';
import { LOGOUT } from '../../../utils/constants';
import AuthService from '../../../api/services/AuthService';

export const login = createAsyncThunk('auth/login', async requestData => {
  return await AuthService.login(requestData);
});

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  thunkAPI.dispatch({ type: LOGOUT });
  return await AuthService.logout();
});

export const requestVerificationCode = createAsyncThunk('auth/requestVerificationCode', async requestData => {
  return await AuthService.requestVerificationCode(requestData);
});

export const checkVerificationCode = createAsyncThunk('auth/checkVerificationCode', async requestData => {
  return await AuthService.checkVerificationCode(requestData);
});

export const resetPassword = createAsyncThunk('auth/resetPassword', async requestData => {
  return await AuthService.resetPassword(requestData);
});

export const verifyEmail = createAsyncThunk('auth/verifyEmail', async requestData => {
  return await AuthService.verifyEmail(requestData);
});
