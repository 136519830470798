import styled from 'styled-components';
import { Button } from '../../components/utils/Button/Button';

export const PageContainer = styled.div`
  padding: 2.4rem 2.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 2.4rem;
  flex-grow: 1;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledButton = styled(Button)`
  width: 12rem;
  height: 4rem;
`;

export const StatisticContainer = styled.div`
  display: flex;
  gap: 1.6rem;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;
