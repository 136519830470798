import React, { useEffect } from 'react';
import {
  BackContainer,
  Container,
  ContentContainer,
  Header,
  LogoutContainer,
  LogoutText,
} from './OnboardingContainer.styles';
import { ReactComponent as BackIcon } from '../../assets/icons/chevron_left.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/slices/auth/asyncThunks';
import OnboardingSteps from '../OnboardingSteps/OnboardingSteps';
import { getQuestionnaire } from '../../store/slices/influencerRequest/asyncThunks';
import LogoComponent from '../LogoComponent/LogoComponent';
import { useMediaQuery } from 'react-responsive';

const OnboardingContainer = ({ children, onBack, hideHeader }) => {
  const dispatch = useDispatch();
  const smallMobile = useMediaQuery({ minWidth: 0, maxWidth: 600 });

  useEffect(() => {
    dispatch(getQuestionnaire());
  }, [dispatch]);

  return (
    <Container $hasHeader={!hideHeader}>
      {!hideHeader && (
        <Header>
          <LogoComponent fullLogo={!smallMobile} width={40} />
          <OnboardingSteps />
          <LogoutContainer onClick={() => dispatch(logout())}>
            <LogoutText>Logout</LogoutText>
            <LogoutIcon />
          </LogoutContainer>
        </Header>
      )}
      <ContentContainer $hasHeader={!hideHeader}>
        {onBack && (
          <BackContainer onClick={onBack}>
            <BackIcon />
          </BackContainer>
        )}
        {children}
      </ContentContainer>
    </Container>
  );
};

export default OnboardingContainer;
