import React, { useRef } from 'react';
import { AuthContainer, Input } from '../../../components';
import { notifyError } from '../../../utils/notify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AlreadyHaveAccountContainer, Form, LoginButton, StyledButton } from './SignUpPage.styles';
import { EMAIL_VERIFICATION, LOGIN } from '../../../components/App/routes';
import { Text14Light } from '../../../components/utils/Text/Text.styles';
import { useNavigate } from 'react-router-dom';
import { VERIFICATION_CODE_TYPES } from '../../../utils/constants';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../../config';
import { requestVerificationCode } from '../../../store/slices/auth/asyncThunks';
import { selectAuthActionsPending } from '../../../store/slices/auth/slice';

export const signUpFormSchema = yup
  .object({
    email: yup.string().trim().required('Email is required').email('Invalid email'),
  })
  .required();

const SignUpPage = () => {
  const { requestVerificationCode: requestVerificationCodePending } = useSelector(selectAuthActionsPending);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recaptchaRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    delayError: 300,
    resolver: yupResolver(signUpFormSchema),
  });

  const onSubmit = async data => {
    const { email } = data;
    const recaptchaValue = await recaptchaRef.current.executeAsync();

    dispatch(
      requestVerificationCode({ email, token: recaptchaValue, type: VERIFICATION_CODE_TYPES.EMAIL_VERIFICATION }),
    )
      .unwrap()
      .then(() => {
        navigate(`/${EMAIL_VERIFICATION}`, { state: { email, type: VERIFICATION_CODE_TYPES.EMAIL_VERIFICATION } });
      })
      .catch(err => {
        recaptchaRef.current.reset();
        notifyError(err.message);
      });
  };

  return (
    <AuthContainer title="Create Account" subtitle="Get started with your email">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input placeholder="Enter your email address" {...register('email')} error={errors.email?.message} />
        <StyledButton title="Continue" type="submit" isLoading={requestVerificationCodePending} />
      </Form>
      <AlreadyHaveAccountContainer>
        <Text14Light>Already have an account?</Text14Light>
        <LoginButton to={`/${LOGIN}`}>Sign In</LoginButton>
      </AlreadyHaveAccountContainer>
      <ReCAPTCHA ref={recaptchaRef} sitekey={config.googleRecaptcha.key} size="invisible" />
    </AuthContainer>
  );
};

export default SignUpPage;
