import React from 'react';
import { ActionsContainer, Card, Content, EditText, Text } from './WithdrawalMethod.styles';
import { Text14Light, Text16Bold } from '../utils/Text/Text.styles';
import { ReactComponent as CheckCircleEmpty } from '../../assets/icons/check-circle-empty.svg';
import { ReactComponent as CheckCircle } from '../../assets/icons/check-circle-broken.svg';

const WithdrawalMethod = ({ title, description, note, isSelected, hasEdit, onClick, onEditClick }) => {
  return (
    <Card onClick={onClick}>
      <Content>
        <Text16Bold>{title}</Text16Bold>
        <Text14Light>{description}</Text14Light>
        <Text>{note}</Text>
      </Content>
      <ActionsContainer>
        {hasEdit ? <EditText onClick={onEditClick}>Edit</EditText> : <div />}
        {isSelected ? <CheckCircle width={24} height={24} /> : <CheckCircleEmpty />}
      </ActionsContainer>
    </Card>
  );
};

export default WithdrawalMethod;
