import styled from 'styled-components';
import { Button } from '../../../components/utils/Button/Button';

export const StyledButton = styled(Button)`
  margin-top: 4.5rem;

  @media only screen and (max-width: 500px) {
    margin-top: auto;
    margin-bottom: 6.5rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media only screen and (min-width: 500px) {
    flex: unset;
  }
`;
