import { createAsyncThunk } from '@reduxjs/toolkit';
import UserService from '../../../api/services/UserService';
import { base64ToFile } from '../../../utils/util';

export const fetchUserInfo = createAsyncThunk('user/fetchUserInfo', async () => {
  return await UserService.fetchUserInfo();
});

export const changePassword = createAsyncThunk('user/changePassword', async requestData => {
  return await UserService.changePassword(requestData);
});

export const fetchUserEarnings = createAsyncThunk('user/fetchUserEarnings', async requestData => {
  return await UserService.fetchUserEarnings(requestData);
});

export const handleWithdrawalMethods = createAsyncThunk('user/handleWithdrawalMethods', async requestData => {
  return await UserService.handleWithdrawalMethods(requestData);
});

export const updateUserBasicInfo = createAsyncThunk('user/updateUserBasicInfo', async requestData => {
  const { name, dateOfBirth, profilePhoto } = requestData;

  const formData = new FormData();
  if (profilePhoto) {
    const profilePhotoFile = base64ToFile(profilePhoto, 'profile-photo');
    formData.append('file', profilePhotoFile, profilePhotoFile.name);
  }

  formData.append('name', name);
  formData.append('dateOfBirth', dateOfBirth);

  return await UserService.updateBasicInfo(formData);
});
