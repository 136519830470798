import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../../../utils/constants';
import {
  acceptAgreementBuilder,
  createInfluencerRequestPasswordBuilder,
  getOrCreateIdVerificationTokenBuilder,
  getQuestionnaireBuilder,
  redeemInvitationCodeBuilder,
  updateBasicInfoBuilder,
} from './builder';

const INIT_STATE = {
  questionnaire: [],
  pending: {
    createInfluencerRequestPassword: false,
    redeemInvitationCode: false,
    updateBasicInfo: false,
    getOrCreateIdVerificationToken: false,
    acceptAgreement: false,
  },
};

export const influencerRequestSlice = createSlice({
  name: 'influencerRequest',
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: builder => {
    createInfluencerRequestPasswordBuilder(builder);
    redeemInvitationCodeBuilder(builder);
    updateBasicInfoBuilder(builder);
    getOrCreateIdVerificationTokenBuilder(builder);
    getQuestionnaireBuilder(builder);
    acceptAgreementBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const selectQuestionnaire = state => state.influencerRequestInfo.questionnaire;
export const selectInfluencerRequestActionsPending = state => state.influencerRequestInfo.pending;

export default influencerRequestSlice.reducer;
