import styled from 'styled-components';
import { theme } from '../../../styles/constants';

export const Container = styled.div`
  color: ${theme.colors.error};
  font-size: 1.3rem;
  min-height: ${props => (props.$twoLine ? '4.2rem' : '2.1rem')};
  letter-spacing: 0.5px;
  margin-left: 0.2rem;
  font-weight: 300;
`;
