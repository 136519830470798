import React, { memo } from 'react';
import {
  CloseWrapper,
  Error,
  ErrorMessageText,
  ErrorText,
  MediaContainer,
  ProgressContainer,
  VoiceContainer,
} from './MediaComponent.styles';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { ReactComponent as VoiceIcon } from '../../assets/icons/voice.svg';
import { Text14Regular } from '../utils/Text/Text.styles';
import { UPLOAD_FILE_CONSTRAINTS, UPLOAD_MEDIA_FILE_ERROR_TYPE } from '../../utils/constants';

const MediaComponent = ({ file, progress, onDelete, type, error }) => {
  const ERROR_MESSAGES = {
    [UPLOAD_MEDIA_FILE_ERROR_TYPE.FILE_SIZE]: `File size is too large (max ${UPLOAD_FILE_CONSTRAINTS[type].MAX_SIZE_IN_MB}MB)`,
    [UPLOAD_MEDIA_FILE_ERROR_TYPE.FILE_TYPE]: `Invalid file type (allowed: ${UPLOAD_FILE_CONSTRAINTS[type].ALLOWED_EXTENSIONS_TEXT})`,
    [UPLOAD_MEDIA_FILE_ERROR_TYPE.AUDIO_DURATION]: 'Audio duration is less than 30 seconds',
  };

  const renderContent = () => {
    switch (type) {
      case UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO:
        return <img src={file.url} alt="influencer-image" />;
      case UPLOAD_FILE_CONSTRAINTS.TYPE.VIDEO:
        return (
          <video autoPlay={false}>
            <source src={file.url} type="video/mp4" />
          </video>
        );
      case UPLOAD_FILE_CONSTRAINTS.TYPE.AUDIO:
        return (
          <VoiceContainer>
            <VoiceIcon />
            <Text14Regular>{file.file ? file.file.name : 'Voice'}</Text14Regular>
          </VoiceContainer>
        );

      default:
        break;
    }
  };
  return (
    <MediaContainer>
      {progress ? (
        <ProgressContainer>
          <CircularProgressbar
            value={progress}
            text={`${progress}%`}
            styles={buildStyles({
              pathColor: 'rgb(91, 22, 196)',
              textColor: 'rgb(91, 22, 196)',
              trailColor: 'rgb(164, 134, 255)',
            })}
          />
        </ProgressContainer>
      ) : (
        <>
          <CloseWrapper onClick={onDelete}>
            <CloseIcon width={15} height={15} />
          </CloseWrapper>
          {renderContent()}
        </>
      )}
      {error && (
        <Error>
          <ErrorText>Upload Failed</ErrorText>
          <ErrorMessageText>{ERROR_MESSAGES[error.type]}</ErrorMessageText>
          <ErrorText>Please try again.</ErrorText>
        </Error>
      )}
    </MediaContainer>
  );
};

export default memo(MediaComponent);
