import React from 'react';
import { Container, IconWrapper, LeftSide } from './Header.styles';
import { Text18Bold } from '../utils/Text/Text.styles';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as ArrowIcon } from '../../assets/icons/chevron_left.svg';

const Header = ({ title, setShowContent, children }) => {
  const smallScreen = useMediaQuery({ minWidth: 0, maxWidth: 768 });

  return (
    <Container>
      <LeftSide>
        {smallScreen && (
          <IconWrapper onClick={() => setShowContent(false)}>
            <ArrowIcon />
          </IconWrapper>
        )}
        <Text18Bold>{title}</Text18Bold>
      </LeftSide>
      {children}
    </Container>
  );
};

export default Header;
