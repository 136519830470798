import styled from 'styled-components';
import { Text14Light } from '../../utils/Text/Text.styles';

export const Button = styled.button`
  border-radius: 100px;
  border: 1px solid ${({ $selected }) => ($selected ? '#4E2D94' : '#C5BEF4')};
  background: ${({ $selected }) => ($selected ? 'linear-gradient(180deg, #371F6B 0%, #4F2D97 100%)' : '#EEECFB')};
  padding: 0.6rem 1.2rem;
  cursor: pointer;
`;

export const Title = styled(Text14Light)`
  line-height: 22px;
  letter-spacing: -0.408px;
  color: ${({ $selected }) => ($selected ? '#fff' : '#54329E')};
`;
