import React from 'react';
import {
  BottomContainer,
  BottomText,
  Card,
  GrossValue,
  HiddenGrossValue,
  PrefixText,
  SuffixText,
  TitleContainer,
  ValueContainer,
  ValueTextContainer,
} from './StatisticCard.styles';
import { Text15Regular, Text28ExtraBold } from '../../utils/Text/Text.styles';
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrow-up-right.svg';
import { formatPrice } from '../../../utils/util';

const StatisticCard = ({ title, icon, valuePrefix, valueSuffix, value, bottomValue, hideGross }) => {
  return (
    <Card>
      <TitleContainer>
        <Text15Regular>{title}</Text15Regular>
        {icon}
      </TitleContainer>
      <ValueContainer>
        <ValueTextContainer>
          {valuePrefix && <PrefixText>{valuePrefix}</PrefixText>}
          <Text28ExtraBold>{formatPrice(value * (hideGross ? 1 : 2))}</Text28ExtraBold>
          {valueSuffix && <SuffixText>{valueSuffix}</SuffixText>}
        </ValueTextContainer>
        {bottomValue && (
          <BottomContainer>
            <BottomText>{`+${bottomValue} this week`}</BottomText>
            <ArrowUpIcon />
          </BottomContainer>
        )}
        {hideGross ? <HiddenGrossValue>0</HiddenGrossValue> : <GrossValue>${formatPrice(value)} Net</GrossValue>}
      </ValueContainer>
    </Card>
  );
};

export default StatisticCard;
