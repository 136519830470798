import React from 'react';
import { Button, Title } from './CryptoNetworkButton.styles';

const CryptoNetworkButton = ({ title, selected, onClick }) => {
  return (
    <Button type="button" $selected={selected} onClick={onClick}>
      <Title $selected={selected}>{title}</Title>
    </Button>
  );
};

export default CryptoNetworkButton;
