import React, { useState } from 'react';
import { Button, ButtonContainer, Title, TooltipContainer, TooltipText } from './RequestWithdrawalButton.styles';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import { Text14Regular } from '../../utils/Text/Text.styles';

const RequestWithdrawalButton = ({ title, onClick, disabled }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <ButtonContainer>
      <Button disabled={disabled} onClick={onClick}>
        <Title $disabled={disabled}>{title}</Title>
        {disabled && <InfoIcon onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} />}
      </Button>
      {showTooltip && (
        <TooltipContainer>
          <Text14Regular>Request Withdrawal</Text14Regular>
          <TooltipText>
            Your balance can be withdrawn 21 days after it’s earned. Minimum withdrawal is $500. You can request up to
            two withdrawals every month.
          </TooltipText>
        </TooltipContainer>
      )}
    </ButtonContainer>
  );
};

export default RequestWithdrawalButton;
