import React from 'react';
import { useSelector } from 'react-redux';

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { FORBIDDEN, HOME, UPDATE_PASSWORD } from '../App/routes';
import { selectUser } from '../../store/slices/user/slice';

const PrivateOutlet = ({ roles }) => {
  const user = useSelector(selectUser);
  const { pathname } = useLocation();

  if (!roles.includes(user.role)) {
    if (pathname === HOME) {
      return <Navigate to={`/${UPDATE_PASSWORD}`} />;
    }
    return <Navigate to={`/${FORBIDDEN}`} />;
  }

  return <Outlet />;
};

export default PrivateOutlet;
