import { axiosApiFreeClient } from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/d';

class AuthService {
  login = async body => {
    try {
      const response = await axiosApiFreeClient.post(`${BASE_URL}/login`, body, {
        withCredentials: true,
      });
      throwErrorIfWrongStatus(response, 200);
      const { accessToken, user } = response.data.data;
      return { accessToken, user };
    } catch (err) {
      throw handleError(err, 'Error occurred while logging in');
    }
  };

  logout = async () => {
    try {
      const response = await axiosApiFreeClient.post(
        `${BASE_URL}/logout`,
        {},
        { withCredentials: true, credentials: 'include' },
      );
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, 'Error occurred while logging out');
    }
  };

  verifyEmail = async ({ email, code, token }) => {
    try {
      const body = {
        email,
        token,
        code,
      };
      const response = await axiosApiFreeClient.post(`${BASE_URL}/verify-email`, body, {
        withCredentials: true,
      });
      throwErrorIfWrongStatus(response, 200);
      const { accessToken, user } = response.data.data;
      return { accessToken, user };
    } catch (err) {
      throw handleError(err, 'Error occurred while verifying email');
    }
  };

  requestVerificationCode = async ({ email, token, type }) => {
    try {
      const body = {
        email,
        token,
        type,
      };
      const response = await axiosApiFreeClient.post(`${BASE_URL}/request-verification-code`, body);
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, 'Error occurred while requesting verification code');
    }
  };

  checkVerificationCode = async ({ email, code, token }) => {
    try {
      const body = {
        email,
        token,
        code,
      };
      const response = await axiosApiFreeClient.post(`${BASE_URL}/check-verification-code`, body);
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, 'Error occurred while checking verification code');
    }
  };

  resetPassword = async ({ email, code, password, token }) => {
    try {
      const body = {
        email,
        token,
        code,
        password,
      };
      const response = await axiosApiFreeClient.post(`${BASE_URL}/reset-password`, body);
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, 'Error occurred while resetting password');
    }
  };
}

export default new AuthService();
