import React, { useEffect } from 'react';
import { Buttons, Form, StyledButton } from './CryptoForm.styles';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../utils/inputs/Input/Input';
import { CRYPTO_NETWORK, WITHDRAWAL_METHOD_TYPE } from '../../../utils/constants';
import CryptoNetworkButton from '../CryptoNetworkButton/CryptoNetworkButton';
import { handleWithdrawalMethods } from '../../../store/slices/user/asyncThunks';
import { WITHDRAW } from '../../App/routes';
import { notifyError } from '../../../utils/notify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUser, selectUserActionsPending } from '../../../store/slices/user/slice';

export const cryptoFormSchema = yup
  .object({
    address: yup.string().trim().required('Address is required'),
  })
  .required();

const CryptoForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { crypto } = user.withdrawalMethod;
  const { handleWithdrawalMethods: handleWithdrawalMethodsPending } = useSelector(selectUserActionsPending);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      address: '',
      network: CRYPTO_NETWORK.TRC_20,
    },
    delayError: 300,
    resolver: yupResolver(cryptoFormSchema),
  });

  useEffect(() => {
    if (crypto) {
      setValue('address', crypto.address);
      setValue('network', crypto.network);
    }
  }, [crypto, setValue]);

  const onSubmit = async data => {
    dispatch(handleWithdrawalMethods({ ...data, withdrawalMethodType: WITHDRAWAL_METHOD_TYPE.CRYPTO }))
      .unwrap()
      .then(() => {
        navigate(`/${WITHDRAW}`);
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input label="USDT Address" placeholder="Enter USDT Address" {...register('address')} error={errors.address?.message} />
      <Controller
        name="network"
        control={control}
        render={({ field }) => (
          <Buttons>
            {Object.keys(CRYPTO_NETWORK).map(key => (
              <CryptoNetworkButton
                key={key}
                title={key.replace('_', ' ')}
                selected={field.value === key}
                onClick={() => field.onChange(key)}
              />
            ))}
          </Buttons>
        )}
      />
      <StyledButton title="Save" type="submit" isLoading={handleWithdrawalMethodsPending} />
    </Form>
  );
};

export default CryptoForm;
