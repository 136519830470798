import React from 'react';
import { Card, CardContent, CategoryText, DollarSign, PriceContainer } from './AnalyticsCard.styles';
import { Text22Bold } from '../../utils/Text/Text.styles';
import { VALUE_OF_ONE_STAR_IN_USD } from '../../../utils/constants';
import { formatPrice } from '../../../utils/util';

const AnalyticsCard = ({ earnings, icon, title }) => {
  return (
    <Card>
      {icon}
      <CardContent>
        <CategoryText>{title}</CategoryText>
        <PriceContainer>
          <DollarSign>$</DollarSign>
          <Text22Bold>{formatPrice(earnings * VALUE_OF_ONE_STAR_IN_USD * 2)}</Text22Bold>
        </PriceContainer>
      </CardContent>
    </Card>
  );
};

export default AnalyticsCard;
