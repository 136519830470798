import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/user/slice';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { INFLUENCER_REQUEST_STATUS } from '../../utils/constants';
import {
  ACCEPT_AGREEMENT,
  CHECKOUT,
  ID_VERIFICATION,
  IN_REVIEW,
  SUBSCRIBE,
  UPDATE_BASIC_INFO,
  UPDATE_PASSWORD,
  UPLOAD_MEDIA,
} from '../App/routes';

const InfluencerRequestOutlet = ({ allowedStatuses }) => {
  const user = useSelector(selectUser);
  const location = useLocation();
  const { pathname } = location;

  const getNextStep = () => {
    switch (user.status) {
      case INFLUENCER_REQUEST_STATUS.EMAIL_VERIFIED:
        if (!user.hasPassword) {
          return UPDATE_PASSWORD;
        } else {
          if (pathname === `/${CHECKOUT}`) {
            return CHECKOUT;
          } else {
            return SUBSCRIBE;
          }
        }
      case INFLUENCER_REQUEST_STATUS.SUBSCRIBED:
        return ID_VERIFICATION;
      case INFLUENCER_REQUEST_STATUS.IDENTIFIED:
        return UPDATE_BASIC_INFO;
      case INFLUENCER_REQUEST_STATUS.ONBOARDED:
        return UPLOAD_MEDIA;
      case INFLUENCER_REQUEST_STATUS.MEDIA_UPLOADED:
        return ACCEPT_AGREEMENT;
      case INFLUENCER_REQUEST_STATUS.COMPLETED:
        return IN_REVIEW;
      default:
        break;
    }
  };

  if (!allowedStatuses.includes(user.status)) {
    const navigateTo = `/${getNextStep()}`;
    if (pathname !== navigateTo) {
      return <Navigate to={navigateTo} />;
    }
  }

  return <Outlet />;
};

export default InfluencerRequestOutlet;
