export const HOME = '/';
export const LOGIN = 'login';
export const SIGN_UP = 'sign-up';
export const FORGOT_PASSWORD = 'forgot-password';
export const EMAIL_VERIFICATION = 'email-verification';
export const RESET_PASSWORD = 'reset-password';
export const SUPPORT = 'support';
export const PRIVACY_POLICY = 'privacy-policy';
export const TERMS_AND_CONDITIONS = 'terms-and-conditions';
export const PERSONAL_INFO = 'personal-info';
export const CHANGE_PASSWORD = 'change-password';
export const FAQS = 'faqs';
export const PROFILE = 'profile';
export const FORBIDDEN = 'forbidden';
export const UPDATE_PASSWORD = 'update-password';
export const CHECKOUT = 'checkout';
export const SUBSCRIBE = 'subscribe';
export const UPDATE_BASIC_INFO = 'update-basic-info';
export const UPLOAD_MEDIA = 'upload-media';
export const IN_REVIEW = 'in-review';
export const ID_VERIFICATION = 'id-verification';
export const ACCEPT_AGREEMENT = 'accept-agreement';
export const WALLET = 'wallet';
export const WITHDRAW = 'wallet/withdraw';
export const WITHDRAWAL_METHOD = 'wallet/withdrawal/method';
