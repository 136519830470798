import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 90rem;
  padding: 4rem 1.6rem 3rem 1.6rem;

  @media only screen and (max-width: 500px) {
    height: 100%;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
