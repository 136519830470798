import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Input from '../../utils/inputs/Input/Input';
import { Form, StyledButton } from './DomesticWireForm.styles';
import { notifyError } from '../../../utils/notify';
import { handleWithdrawalMethods } from '../../../store/slices/user/asyncThunks';
import { WITHDRAW } from '../../App/routes';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUser, selectUserActionsPending } from '../../../store/slices/user/slice';
import { WITHDRAWAL_METHOD_TYPE } from '../../../utils/constants';

export const domesticWireFormSchema = yup
  .object({
    accountNumber: yup.string().trim().required('Account Number is required'),
    routingNumber: yup.string().trim().required('Routing Number is required'),
    beneficiaryName: yup.string().trim().required('Beneficiary Name is required'),
    beneficiaryAddress: yup.string().trim().required('Beneficiary Address is required'),
  })
  .required();

const DomesticWireForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { domesticWire } = user.withdrawalMethod;
  const { handleWithdrawalMethods: handleWithdrawalMethodsPending } = useSelector(selectUserActionsPending);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      accountNumber: '',
      routingNumber: '',
      beneficiaryName: '',
      beneficiaryAddress: '',
    },
    delayError: 300,
    resolver: yupResolver(domesticWireFormSchema),
  });

  useEffect(() => {
    if (domesticWire) {
      setValue('accountNumber', domesticWire.accountNumber);
      setValue('routingNumber', domesticWire.routingNumber);
      setValue('beneficiaryName', domesticWire.beneficiaryName);
      setValue('beneficiaryAddress', domesticWire.beneficiaryAddress);
    }
  }, [domesticWire, setValue]);

  const onSubmit = async data => {
    dispatch(handleWithdrawalMethods({ ...data, withdrawalMethodType: WITHDRAWAL_METHOD_TYPE.DOMESTIC_WIRE }))
      .unwrap()
      .then(() => {
        navigate(`/${WITHDRAW}`);
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        label="Beneficiary Name"
        placeholder="Enter Beneficiary Name"
        {...register('beneficiaryName')}
        error={errors.beneficiaryName?.message}
      />
      <Input
        label="Account Number"
        placeholder="Enter Account Number"
        {...register('accountNumber')}
        error={errors.accountNumber?.message}
      />
      <Input
        label="Routing Number"
        placeholder="Enter Routing Number"
        {...register('routingNumber')}
        error={errors.routingNumber?.message}
      />
      <Input
        label="Beneficiary Address"
        placeholder="Enter Beneficiary Address"
        {...register('beneficiaryAddress')}
        error={errors.beneficiaryAddress?.message}
      />
      <StyledButton title="Save" type="submit" isLoading={handleWithdrawalMethodsPending} />
    </Form>
  );
};

export default DomesticWireForm;
