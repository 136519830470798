import styled from 'styled-components';
import { Text14Light } from '../../../components/utils/Text/Text.styles';

export const PageContainer = styled.div`
  max-height: 100%;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 2.4rem;
  padding: 2.4rem;
  max-height: calc(100% - 6.8rem);
  background: #f5f5fd;
`;

export const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const Text = styled(Text14Light)`
  line-height: 2.4rem;
  word-break: break-word;
`;
