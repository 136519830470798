import styled from 'styled-components';
import { Button } from '../../../components/utils/Button/Button';
import { Text32ExtraBold } from '../../../components/utils/Text/Text.styles';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 4.2rem;

  @media only screen and (max-width: 500px) {
    height: 100%;
    padding: 4.2rem 1.6rem 5rem 1.6rem;

    svg {
      margin-bottom: 10rem;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 42rem;
  margin-bottom: 10rem;

  @media only screen and (max-width: 500px) {
    width: 100%;
    margin-bottom: 2rem;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 5.3rem;
  @media only screen and (max-width: 500px) {
    margin-top: auto;
  }
`;

export const Title = styled(Text32ExtraBold)`
  margin-bottom: 4.8rem;
  text-align: center;
`;
