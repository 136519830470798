import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../../../utils/constants';
import { cancelSubscriptionBuilder, createPaymentSessionBuilder, getPaymentSessionBuilder } from './builder';

const INIT_STATE = {
  pending: {
    createPaymentSession: false,
    cancelSubscription: false,
    getPaymentSession: false,
  },
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: builder => {
    createPaymentSessionBuilder(builder);
    cancelSubscriptionBuilder(builder);
    getPaymentSessionBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const selectPaymentActionsPending = state => state.paymentInfo.pending;

export default paymentSlice.reducer;
