import React, { useEffect } from 'react';
import { Form, StyledButton } from './InternationalWireForm.styles';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Input from '../../utils/inputs/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { handleWithdrawalMethods } from '../../../store/slices/user/asyncThunks';
import { notifyError } from '../../../utils/notify';
import { useNavigate } from 'react-router-dom';
import { WITHDRAW } from '../../App/routes';
import { selectUser, selectUserActionsPending } from '../../../store/slices/user/slice';
import { WITHDRAWAL_METHOD_TYPE } from '../../../utils/constants';

export const internationalWireFormSchema = yup
  .object({
    swiftCode: yup.string().trim().required('Swift Code is required'),
    bankName: yup.string().trim().required('Bank Name is required'),
    beneficiaryName: yup.string().trim().required('Beneficiary Name is required'),
    beneficiaryIBAN: yup.string().trim().required('Beneficiary IBAN is required'),
    beneficiaryAddress: yup.string().trim().required('Beneficiary Address is required'),
  })
  .required();

const InternationalWireForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { internationalWire } = user.withdrawalMethod;
  const { handleWithdrawalMethods: handleWithdrawalMethodsPending } = useSelector(selectUserActionsPending);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      swiftCode: '',
      bankName: '',
      beneficiaryName: '',
      beneficiaryIBAN: '',
      beneficiaryAddress: '',
    },
    delayError: 300,
    resolver: yupResolver(internationalWireFormSchema),
  });

  useEffect(() => {
    if (internationalWire) {
      setValue('swiftCode', internationalWire.swiftCode);
      setValue('bankName', internationalWire.bankName);
      setValue('beneficiaryName', internationalWire.beneficiaryName);
      setValue('beneficiaryIBAN', internationalWire.beneficiaryIBAN);
      setValue('beneficiaryAddress', internationalWire.beneficiaryAddress);
    }
  }, [internationalWire, setValue]);

  const onSubmit = async data => {
    dispatch(handleWithdrawalMethods({ ...data, withdrawalMethodType: WITHDRAWAL_METHOD_TYPE.INTERNATIONAL_WIRE }))
      .unwrap()
      .then(() => {
        navigate(`/${WITHDRAW}`);
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        label="Bank Swift Code"
        placeholder="Enter Bank Swift Code"
        {...register('swiftCode')}
        error={errors.swiftCode?.message}
      />
      <Input
        label="Bank Name"
        placeholder="Enter Bank Name"
        {...register('bankName')}
        error={errors.bankName?.message}
      />
      <Input
        label="Beneficiary Name"
        placeholder="Enter Beneficiary Name"
        {...register('beneficiaryName')}
        error={errors.beneficiaryName?.message}
      />
      <Input
        label="Beneficiary IBAN"
        placeholder="Enter Beneficiary IBAN"
        {...register('beneficiaryIBAN')}
        error={errors.beneficiaryIBAN?.message}
      />
      <Input
        label="Beneficiary Address"
        placeholder="Enter Beneficiary Address"
        {...register('beneficiaryAddress')}
        error={errors.beneficiaryAddress?.message}
      />
      <StyledButton title="Save" type="submit" isLoading={handleWithdrawalMethodsPending} />
    </Form>
  );
};

export default InternationalWireForm;
